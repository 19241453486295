@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-image-gallery/styles/css/image-gallery.css';

@layer base {
  body {
    @apply overflow-hidden text-primary font-secondary bg-white;
  }
  .h1 {
    @apply text-[20px] md:text-[40px] font-primary font-bold text-primary capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .section {
    @apply h-screen w-screen;
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white rounded-lg;
  }
  .cover {
    @apply hidden group-hover:block absolute bottom-0 bg-white/50 w-full h-full
  }
  .cover p {
    @apply flex justify-center items-center h-full font-primary font-bold text-primary text-[2rem] whitespace-pre-wrap
  }
  .model {
    @apply fixed top-0 left-0 flex justify-center items-center 
    bg-black opacity-0 invisible overflow-hidden scale-0 z-[999] 
    transition-opacity duration-500 transition-transform 
  }
  .model.open {
    @apply visible opacity-100 scale-100 
  }
  .grid-spacer {
    height: 10vh; /* Adjust this value based on your design */
  }
}

